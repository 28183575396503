export default {
    _LANGUAGES: '_LANGUAGES',
    _POSTS: '_POSTS',
    _POST_DETAIL: '_POST_DETAIL',
    _NEW: '_NEW',
    ACTIONS: {
        _LANGUAGES: {
            CHANGE_LANGUAGES: 'CHANGE_LANGUAGES'
        },
        _POSTS: {
            CHANGE_CATEGORIES: 'CHANGE_CATEGORIES'
        },
        _POST_DETAIL: {
            CHANGE_ID_POST: 'CHANGE_ID_POST',
            CHANGE_CACHE_CONTENT: 'CHANGE_CACHE_CONTENT'
        },
        _NEW: {
            CHANGE_POSTS: 'CHANGE_POSTS'
        },
    }
}